<template>
  <div class="about">
    <h1>This is an about page</h1>
    <p v-for="(item, idx) in test" :key="idx">{{item}}</p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class About extends Vue {
  test: string[] = [];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/WeatherForecast`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.test = res.data.map((s: any) => s.summary));
  }
}
</script>
